









import { Component, Mixins } from 'vue-property-decorator'

import NavMenu from '@/partials/components/NavMenu.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    NavMenu
  }
})
export default class UsersMenuLayout extends Mixins(PermissionsMixin) {
  availableRoutes: AvailableRoute[] = []
  created () {
    this.availableRoutes = [
      {
        label: 'Usuários',
        path: '/users',
        permission: this.permissions.USER.READ
      }
      // {
      //   label: 'Papéis',
      //   path: '/users/roles',
      //   permission: this.permissions.USERROLE.READ
      // }
    ]
  }
}
