






















import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/permissionsMixin'

@Component
export default class NavMenu extends Mixins(PermissionsMixin) {
  @Prop() availableRoutes!: Array<AvailableRoute>
  @Prop({ type: String }) entity!: 'event'
  defaultRoute = ''
  routes: Array<AvailableRoute> = []
  currRoute = ''
  destroying = false

  tabSelected (tab: number) {
    if (!this.destroying) {
      let changeTo = ''
      const routesPath = this.routes.map(route => route.path)

      if (routesPath[tab] !== undefined) {
        changeTo = routesPath[tab]
      } else {
        changeTo = this.defaultRoute
      }

      if (routesPath.includes(this.currRoute) && changeTo !== this.currRoute) {
        this.$router.push(changeTo)
      }
    }
  }

  created () {
    this.availableRoutes.forEach(route => {
      if (this.checkForPermission(route.permission)) {
        this.routes.push(route)
        if (!this.defaultRoute) {
          this.defaultRoute = route.path
        }
      }
    })
  }

  private checkForPermission (permission?: string | PermissionOnEntity) {
    if (permission === undefined) return true
    if (typeof permission === 'object') {
      if (this.entity === 'event') {
        return this.hasEventPermission(permission.id, permission.name)
      } else if (this.entity === 'service') {
        return this.hasServicePermission(permission.id, permission.name)
      }
    } else {
      return this.hasPermission(permission)
    }
  }

  @Watch('$route.path', { immediate: true })
  updateCurrPath () {
    this.currRoute = this.$route.path.slice(-1) === '/' ? this.$route.path.slice(0, -1) : this.$route.path
  }
}
